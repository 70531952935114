<template>
	<div>
		<app-layout>
			<template v-slot:header>
				<Header :title="$t('advising')"
				        :isFilter="false"/>
			</template>
			<template v-slot:header-mobile>
				<HeaderMobile :title="$t('advising')"
				              :isFilter="false"/>
			</template>
			<div class="row mb-4">
				<div class="w-100 d-none d-lg-block"></div>
				<div class="col-12 col-lg-3 col-xxl-3">
					<b-input-group size="lg" class="mb-2">
						<b-input-group-prepend is-text>
							<i class="ri-search-line"></i>
						</b-input-group-prepend>
						<b-form-input
							type="search"
							:placeholder="$t('search')"
							@input="searchStudent"
						></b-form-input>
					</b-input-group>

					<div class="border p-4 rounded overflow-y-scroll overflow-x-hidden w-100"
					     style="height: calc(100vh - 220px)"
					>
						<div v-if="isStudents === true">
							<div v-for="(item, key) in students" v-bind:key="key">
								<b-button
									:variant="
                      (current_student === item.student_program_id
                        ? 'outline-primary'
                        : 'outline-secondary text-dark') +
                      ' btn-block justify-content-start highhonor-auto font-weight-medium line-height-normal py-2 text-left mb-2 flex-column align-items-start h-auto'
                    "
									@click="getStudent(item)"
								>
									<span>{{ toUpperCase(item.name + "  " + item.surname) }}</span>
									<span class="text-muted">{{ item.student_number }}</span>
								</b-button>
							</div>
						</div>
						<div v-else>
							<div class="spinner-border text-light mx-auto d-flex"></div>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-9 col-xxl-9">
					<div class="w-100" v-if="showStudent == true">
						<h5 class="mt-1 mb-3">
							{{ current_student_data.name + " " + current_student_data.surname }}
						</h5>
						<div class="row">
							<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
								<b-form-group :label="$t('student_number')">
									{{ current_student_data.student_number }}
								</b-form-group>
							</div>

							<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
								<b-form-group :label="$t('email')">
									{{ current_student_data.email }}
								</b-form-group>
							</div>

							<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
								<b-form-group :label="$t('gsm_no')">
									{{ current_student_data.mobile_tel }}
								</b-form-group>
							</div>
						</div>
						<div class="row">
							<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
								<b-form-group :label="$t('student_status')">
									{{ current_student_data.student_status_name }}
								</b-form-group>
							</div>

							<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
								<b-form-group :label="$t('semester_status')">
									{{ current_student_data.semester_status_name }}
								</b-form-group>
							</div>

							<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
								<b-form-group :label="$t('faculty_name')">
									{{ current_student_data.faculty_name }}
								</b-form-group>
							</div>

							<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
								<b-form-group :label="$t('program_name')">
									{{ current_student_data.program_name }}
								</b-form-group>
							</div>
						</div>
						<div class="row">
							<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
								<b-form-group :label="$t('cgpa')">
									{{ current_student_data.cgpa }}
								</b-form-group>
							</div>

							<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
								<b-form-group :label="$t('credits_earned')">
									{{ current_student_data.credits }}
								</b-form-group>
							</div>

							<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
								<b-form-group :label="$t('payment_status')">
									{{ this.$t('payment_status_' + current_student_data.payment_status) }}
								</b-form-group>
							</div>
						</div>
						<div class="row">
							<div class="col-12 d-flex align-items-center">
                                <a
                                    :href="'/student/programs/'+current_student_data.student_program_id+'/slots'"
                                    class="btn btn-outline-primary"
                                    target="_blank"
                                >
                                    {{ $t('slots') }} <i class="ri-arrow-right-line"></i>
                                </a>

								<b-button variant="outline-primary ml-3" @click="downloadTranscript(current_student_data)">
									<span class="d-flex">{{ $t('transcript') }} <i class="ri-arrow-right-line"></i></span>
								</b-button>

							</div>
						</div>
					</div>
				</div>
			</div>
		</app-layout>
	</div>
</template>
<script>
	// Template
	import AppLayout from "@/layouts/AppLayout";
	import Header from "@/layouts/AppLayout/Header";
	import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

	// Services
	import StudentProgramService from "@/services/StudentProgramService";
	import StudentDocumentService from "@/services/StudentDocumentService";

	export default {
		components: {
			AppLayout,
			Header,
			HeaderMobile,
		},
		metaInfo() {
			return {
				title: this.$t("advising"),
			};
		},
		data() {
			return {
				isStudents: false,
				students: [],
				royalStudents: [],
				current_student: null,
				current_student_data: {},
				showStudent: false,
			};
		},
		watch: {},

		computed: {},

		methods: {
			turkishStringToUpperCase(string) {
				let letters = {i: "İ", ş: "Ş", ğ: "Ğ", ü: "Ü", ö: "Ö", ç: "Ç", ı: "I"};
				string = string.replace(/(([iışğüçö]))/g, function (letter) {
					return letters[letter];
				});
				return string.toUpperCase();
			},

			searchStudent(key) {
				key = this.turkishStringToUpperCase(key);
				if (key.length > 2) {
					const filteredArray = this.students.filter(function (item) {
						return (
							item.student_number.includes(key) ||
							item.name.includes(key) ||
							item.surname.includes(key)
						);
					});
					this.students = filteredArray;
				}
				else {
					this.students = this.royalStudents;
				}
			},

			getStudent(item) {
				this.showStudent = true;
				this.current_student_data = item;
			},

			async getStudents() {
				this.isStudents = false;
				StudentProgramService.getAdvisorStudents().then((response) => {
					if (response.data.success) {
						this.isStudents = true;
						this.royalStudents = response.data.data;
						this.students = response.data.data;
					}
				});
			},
			downloadTranscript(row) {
                if(this.checkPermission('studentdocument_transcript')){
                    StudentDocumentService.downloadTranscript(row.student_program_id, this.$i18n.locale)
                                          .then(response => {
                                              this._downloadFile(response, row.student_number + '-' + this.$t('transcript') + '.pdf')
                                          })
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }

			}
		},
		created() {
			this.getStudents();
		},
		mounted() {
		}
	}
</script>
